
/*-----------------------------------------------------------------------------------
	typography.scss
-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Basic
-----------------------------------------------------------------*/

dl, dt, dd, ol, ul, li {
	margin: 0;
	padding: 0;
}

em {
	font-family: var(--#{$cnvs-prefix}secondary-font);
}

.clear {
	clear: both;
	display: block;
	font-size: 0px;
	height: 0px;
	line-height: 0;
	width: 100%;
	overflow:hidden;
}

@mixin selection {
	background: var(--#{$cnvs-prefix}themecolor);
	color: var(--#{$cnvs-prefix}body-bg);
	text-shadow: none;
}

::selection {
	@include selection;
}

::-moz-selection {
	@include selection;
}

::-webkit-selection {
	@include selection;
}

:active,
:focus { outline: none !important; }


/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/


body {
	line-height: 1.5;
	font-size: var(--#{$cnvs-prefix}font-size-body);
	font-family: var(--#{$cnvs-prefix}body-font);
	background: var(--#{$cnvs-prefix}body-bg-boxed);
}


a {
	&:not(.btn-link):not(.text-decoration-underline) {
		text-decoration: none !important;
	}
	color: var(--#{$cnvs-prefix}link-color);
	&:hover {
		color: var(--#{$cnvs-prefix}link-hover-color);
	}
	img { border: none; }
}

img { max-width: 100%; }

iframe {
	width: 100%;
	border: 0 !important;
	overflow: hidden !important;
}


/* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/


h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--#{$cnvs-prefix}heading-color);
	font-weight: var(--#{$cnvs-prefix}headings-font-weight);
	line-height: var(--#{$cnvs-prefix}line-height-base);
	margin: 0 0 30px 0;
	font-family: var(--#{$cnvs-prefix}primary-font);
}

h5,
h6 {
	font-weight: bold;
	margin-bottom: 20px;
}

h1 {
	font-size: var(--#{$cnvs-prefix}font-size-h1);
}

h2 {
	font-size: var(--#{$cnvs-prefix}font-size-h2);
}

h3 {
	font-size: var(--#{$cnvs-prefix}font-size-h3);
}

h4 {
	font-size: var(--#{$cnvs-prefix}font-size-h4);
}

h5 {
	font-size: var(--#{$cnvs-prefix}font-size-h5);
}

h6 {
	font-size: var(--#{$cnvs-prefix}font-size-h6);
}

.h5 {
	font-size: $h5-font-size;
}

.h6 {
	font-size: $h6-font-size;
}

h4 {
	font-weight: 600;
}

h5,
h6 {
	font-weight: bold;
}

@include media-breakpoint-down(md) {

	h1 {
		font-size: 1.75rem;
	}

	h2 {
		font-size: 1.5rem;
	}

	h3 {
		font-size: 1.25rem;
	}

	h4 {
		font-size: 1rem;
	}

}

h1,
h2,
h3,
h4,
h5,
h6 {
	> span:not(.nocolor):not(.badge) {
		color: var(--#{$cnvs-prefix}themecolor);
	}
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form { margin-bottom: 30px; }

small {
	font-size: var(--#{$cnvs-prefix}font-size-small);
}
